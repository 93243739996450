<template>
  <v-sheet>
    <Dialog :dialog="Banking" card-text-class="px-4 py-0" :dialogWidth="500">
      <template v-slot:title
        >Create Qr Sales
        <v-spacer></v-spacer>
      </template>

      <template v-slot:body>
        <v-container>
          <template>
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
              <v-row>
                <v-form
                  style="width: 460px !important"
                  ref="customerForm"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="saveRecord"
                >
                  <v-col md="12" class="py-1">
                    <label for="payment-date" class="field-label required mb-1">Sales Date</label>
                    <DatePicker
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[vrules.required(paymentRecordData.date, 'date')]"
                      :customClass="{ required: !paymentRecordData.date }"
                      id="payment-date"
                      v-on:change="selectPv()"
                      v-model="paymentRecordData.date"
                    ></DatePicker>
                  </v-col>
                  <v-col md="12" class="py-1">
                    <label for="payment-date" class="field-label required mb-1">Amount</label>
                    <QuantityInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="payment-date"
                      v-model="paymentRecordData.total_cash"
                    ></QuantityInput>
                  </v-col>
                </v-form>
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>

      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="saveRecord()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import { UPLOAD } from "@/core/services/store/request.module";

import Dialog from "@/view/components/Dialog";
//import FileUpload from "@/view/components/FileUpload";
import { formatMoney } from "accounting-js";
import { CREATE_QR_SALE } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

import QuantityInput from "@/view/components/QuantityInput";
import moment from "moment-timezone";

import DatePicker from "@/view/components/DatePicker";
//import TextAreaInput from "@/view/components/TextAreaInput";
// import SelectInput from "@/view/components/SelectInput";
// import PurchaseDocument from "@/view/components/PurchaseDocument";
export default {
  name: "item-dialog",
  title: "Dialog Item",

  data() {
    return {
      record: [],
      formValid: true,
      searchDate: null,
      product: [],

      amount: 0,
      filterLists: [
        {
          refrence: null,
          amount: null,
          file: null,
          files: null,
          //     files: [
          //   {
          //     file: null,
          //     name: null,
          //     suffix: null,
          //     size: null,
          //     id: null,
          //   },
          // ],
        },
      ],
      className: null,
      excelLoading: null,
      Banking: false,
      paymentThroughList: [
        { text: "Petty Cash", value: 1 },
        { text: "Undeposited Founds", value: 2 },
      ],
      paymentModeList: [
        { text: "Cash", value: 1 },
        { text: "Credit Card Citi Bank", value: 2 },
        { text: "Credit Card C I M B", value: 3 },
        { text: "Credit Card Public Bank", value: 4 },
        { text: "Boss", value: 4 },
      ],
      paymentRecordData: {
        date: null,
        total_cash: 0,
      },
      paymentRecord: [
        {
          po_number: "PO0001",
          paymentDate: "25/10/2023",
          venderName: "business thrust techsoft pvt ltd",
          amount: 309.88,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    async updateFile(index, file) {
      const _this = this;

      try {
        _this.primaryLoader = true;
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        _this.$store
          .dispatch(UPLOAD, { url: "file-manager", data: formData })
          .then((data) => {
            this.filterLists[index].files = data[0]?.file?.url;
            console.log(data[0].file.url);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            _this.primaryLoader = false;
          });
      } catch (error) {
        console.log(error);
      }

      // if (file && file.name) {
      //   this.files[index].base64 = await this.convert_base_64(file);
      //   console.log(this.files[index].base64)
      //   this.files[index].name = file.name.split(".").slice(0, -1).join(".");
      //   this.files[index].suffix = `${file.name.split(".").pop()}`;
      //   this.files[index].size = file?.size;
      //   this.files[index].type = file?.type;
      //   this.files[index].id = file?.id;

      // } else {
      //   this.files[index].base64 = null;
      //   this.files[index].name = null;
      //   this.files[index].suffix = null;
      //   this.files[index].size = file?.size;
      //   this.files[index].type = file?.type;
      //   this.files[index].id = file?.id;
      // }
    },
    selectPv() {
      this.paymentRecordData.date = this.searchDate;
    },
    totalamount(item, index) {
      this.product = item;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.amount);
      });
      this.amount = sum;
      if (sum > this.paymentRecordData.total_cash) {
        // this.filterLists[index].amount = 0;
        // this.amount=0;
      }
      console.log(index);
      console.log(sum);
      console.log(this.paymentRecordData.total_cash, "this.paymentRecordData.total_cash");
      // this.discount_total = Number(sum);
      //  this.getDiscount();
    },
    addFilter() {
      this.filterLists.push({
        refrence: null,
        amount: null,
      });
    },
    removeFilter(index) {
      this.filterLists.splice(index, 1);
    },
    async saveRecord() {
      if (!this.$refs.customerForm.validate()) {
        return false;
      }
      console.log(this.amount, "amount");
      console.log(this.paymentRecordData.total_cash, "total_cash");

      try {
        const customer = await CREATE_QR_SALE(this.paymentRecordData);
        console.log(customer);
        this.$router.replace({
          name: "qrsalessummary",
          query: { t: new Date().getTime() },
        });
        this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Qr Sales Created Successfully." },
        ]);
        this.Banking = false;
        this.$emit("success", "true");
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    getTotals() {
      this.product = this.detail;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.sales);
      });

      this.paymentRecordData.total_cash = Number(sum);

      return this.paymentRecordData.total_cash;
    },

    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
  watch: {
    dialog(params) {
      this.Banking = params;
      if (!this.Banking) {
        this.paymentRecordData.date = null;
        this.paymentRecordData.total_cash = null;
      }
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  components: {
    Dialog,
    //FileUpload,
    DatePicker,
    // TextAreaInput,
    // SelectInput,
    // PurchaseDocument,
    QuantityInput,
  },
};
</script>

<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
